<template>
  <vs-table max-items="10" :data="$store.state.MwbLeads">
    <template slot="thead">
      <vs-th>Date</vs-th>
      <vs-th> Can-ID</vs-th>
      <vs-th>Name</vs-th>
      <vs-th>Level</vs-th>
      <vs-th>City</vs-th>
      <vs-th>Courses</vs-th>
      <vs-th>Comments</vs-th>
      <vs-th>Visit Count</vs-th>
      <vs-th>Visit Status</vs-th>
      <vs-th>Coming From</vs-th>
      <vs-th>SPOC</vs-th>
      <vs-th>Approved</vs-th>
      <vs-th>Actions</vs-th>
    </template>

    <template slot-scope="{ data }">
      <tbody ref="tbody">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ tr.engagement_added_on }}</vs-td>
          <vs-td>{{ tr.identity }}</vs-td>
          <vs-td>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="12"
            >
              <vx-tooltip :title="'Engagement'" :text="tr.tooltipengagement">{{
                tr.person_name
              }}</vx-tooltip>
            </vs-col>
          </vs-td>
          <vs-td>{{ tr.level }}</vs-td>
          <vs-td>{{ tr.city }}</vs-td>
          <vs-td>{{ tr.courses }}</vs-td>
          <vs-td>{{ tr.engagement_details }}</vs-td>
          <vs-td>{{ tr.visit_count }}</vs-td>
          <vs-td>{{ tr.visit_status }}</vs-td>
          <vs-td v-if="tr.coming_from == 'mf'">Scheduled by SPOC</vs-td>
          <vs-td v-else-if="tr.coming_from == 'miles'">Clevertap</vs-td>
          <vs-td v-else>Direct Walkin</vs-td>
          <vs-td>{{ getSpocById(tr.engagement_added_id) }}</vs-td>
          <vs-td>{{ tr.visit_approved }}</vs-td>
          <vs-td>
            <vs-row>
              <div @click="openPopupEmit(tr)">
                <vs-icon icon="info" size="small" color="dark"></vs-icon>
              </div>
              <!-- <img
                style="margin-right: 10%"
                @click="openPopupEmit(tr)"
                src="https://img.icons8.com/material-outlined/24/000000/info.png"
              />-->
              <div v-if="tr.visit_approved === 'No'" @click="openApproval(tr)">
                <vs-icon icon="warning" size="small" color="warning"></vs-icon>
              </div>
              <!-- <img src="https://img.icons8.com/color/24/000000/warning-shield.png" /> -->
              <div
                v-if="tr.visit_approved === 'Rejected'"
                @click="openApproval(tr)"
              >
                <vs-icon
                  icon="highlight_off"
                  size="small"
                  color="danger"
                ></vs-icon>
              </div>
              <div v-if="tr.visit_approved === 'Yes'" @click="openApproval(tr)">
                <vs-icon
                  icon="check_circle_outline"
                  size="small"
                  color="success"
                ></vs-icon>
              </div>
            </vs-row>
          </vs-td>
        </vs-tr>
      </tbody>
    </template>
  </vs-table>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
import EventBus from "../eventbus.js";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
export default {
  props: {
    spoc_names: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dateRange: {
        startDate: "",
        endDate: new Date()
      },
      cities: [],
      city: "",
      selectedpagination: 1,
      totalpagination: 0,
      identity_seaarch: ""
    };
  },
  components: {
    vSelect,
    DateRangePicker
  },
  methods: {
    analyzePagination() {
      if (this.selectedpagination === 1) {
        this.loadvisits(1);
      } else {
        this.selectedpagination = 1;
      }
    },
    getSpocById(id) {
      // console.log(this.spoc_names);
      let name = "";
      this.spoc_names.forEach(spoc => {
        if (id === spoc.id) {
          name = spoc.first_name;
        }
      });
      return name;
    },
    getSpocs() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          this.loggedin = response.data.logged_in_user_id;
          this.spoc_names = response.data.spocs;
          this.extractSpocByCity(response.data.spocs);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    extractSpocByCity(response) {
      var unique = response
        .map(name => {
          return {
            count: 1,
            name: name.city
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.city = this.cities[0];
      // this.updateValues();
      this.loadvisits(1);
    },
    refresh() {
      this.identity_seaarch = "";
      this.city = this.cities[0];
      this.loadvisits();
      this.dateRange.endDate = new Date();
      let date = new Date();
      let pastdate = date.getDate() - 7;
      date.setDate(pastdate);
      this.dateRange.startDate = date;
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    loadvisits(pagination) {
      let url = `${constants.SERVER_API}getVisits`;

      let obj = {
        from_date: this.datatoTimestamp(this.dateRange.startDate),
        to_date: this.datatoTimestamp(this.dateRange.endDate),
        city: this.city,
        page: pagination
      };
      if (this.identity_seaarch !== "") {
        obj.identity = this.identity_seaarch;
      }
      // if (search === true) {
      //   obj.page = 1;
      // } else {
      //   obj.page = this.selectedpagination;
      // }
      // let obj2 = {
      //   identity: identity,
      //   from_date: this.datatoTimestamp(this.dateRange.startDate),
      //   to_date: this.datatoTimestamp(this.dateRange.endDate),
      //   city: this.city
      // };
      // let params = {};
      // if (identity === "" || identity === undefined) {
      //   params = obj;
      // } else {
      //   params = obj2;
      // }
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          response.data.visit_data.data.forEach(visit => {
            visit.visit_count = visit.engagement.visit_count;
            visit.visit_status = visit.engagement.visit_status;
            visit.coming_from = visit.engagement.coming_from;
            visit.visit_approved = visit.engagement.visit_approved;
            visit.tooltipengagement = visit.engagement.details;
            visit.engagement_id = visit.engagement.id;
            visit.engagements = [];
            visit.mobile = [];
            visit.emails = [];
          });
          this.totalpagination = response.data.visit_data.last_page;
          this.$store.commit("MOUNT_MWB_LEADS", response.data.visit_data.data);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    openApproval(tr) {
      EventBus.$emit("open-approval-popup", tr);
    },
    async openPopupEmit(payload) {
      console.log(payload);
      this.openBigPopup(payload.id);
      // this.$vs.loading();
      // let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${payload.can_id}&person_id=${payload.person_id}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     this.$store.commit("UPDATE_NEW_DATA", [
      //       payload,
      //       response.data.engagements,
      //       response.data.mobile,
      //       response.data.emails
      //     ]);
      //     this.$vs.loading.close();
      //     EventBus.$emit("open-student-details-popup", payload);
      //   })
      //   .catch(error => {
      //     // if (error.response) {
      //     //   if (error.response.status === 401) {
      //     //     this.ReDirect_to_login();
      //     //   }
      //     // }
      //     this.handleError(error);
      //   });
    }
  }
};
</script>

<style></style>

<template>
  <div>
    <vs-card actionable class="cardx">
      <div slot="header">
        <vs-row>
          <vs-col vs-w="2">
            <h4>Visit Approval</h4>
          </vs-col>
          <vs-col v-if="logged_in_team == 'Admin' || no_reporting_to == true" vs-w="3">
            <vs-col vs-w="2" vs-type="flex">
              <vs-radio
                v-model="selectedSpocRadio"
                vs-name="selectedSpocRadio"
                vs-value="CM"
                ><b style="color: #7367f0">CM</b></vs-radio
              >&nbsp;&nbsp;
              <vs-radio
                v-model="selectedSpocRadio"
                vs-name="selectedSpocRadio"
                vs-value="SR"
                ><b style="color: #7367f0">SR</b></vs-radio
              >&nbsp;&nbsp;
              <vs-radio
                v-model="selectedSpocRadio"
                vs-name="selectedSpocRadio"
                vs-value="GM"
                ><b style="color: #7367f0">GM</b></vs-radio
              >
            </vs-col>
          </vs-col>
          <vs-col
            v-if="selectedSpocRadio == 'CM' && logged_in_team == 'Admin' || no_reporting_to == true && selectedSpocRadio != 'GM'"
            vs-type="flex"
            vs-align="flex-start"
            vs-w="2"
          >
            <div>
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon" href.prevent>
                  City Classification
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown">
                  <treeselect
                    :value-consists-of="'LEAF_PRIORITY'"
                    :multiple="true"
                    value-format="object"
                    :options="city_classification_list"
                    placeholder="Select Spoc"
                    v-model="selected_ccf_spocs"
                  />
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-row style="margin-bottom: 2%">
        <vs-col
          v-if="selectedSpocRadio != 'GM'"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <!-- <v-select
            id="courseselect"
            class="w-full"
            placeholder="City"
            v-model="city"
            :options="cities"
            label="text"
            style="height: 20px; z-index: 1000"
          ></v-select>-->
          <treeselect
            v-model="initSelectedBDE"
            :multiple="true"
            :options="treeDataBDE"
            style="width: 240px"
          />
        </vs-col>
        <vs-col
          v-if="selectedSpocRadio == 'GM'"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <v-select
            id="courseselect"
            class="w-full"
            placeholder="Select GM"
            v-model="selected_gm_spoc"
            :options="gm_spocs_list"
          ></v-select>
          <!-- <vs-select
            width="80%"
            v-model="selected_gm_spoc"
            placeholder="Select GM"
            >
            <vs-select-item :key="index" :value="item.id" :text="item.full_name" v-for="(item,index) in gm_spocs" />
          </vs-select> -->
        </vs-col>

        <vs-col vs-justify="flex-start" vs-align="center" vs-w="1.75">
          <!-- <v-select
            id="courseselect"
            class="w-full"
            placeholder="Visit Type"
            v-model="visitType"
            :options="visitTypes"
            label="text"
            style="height: 20px; z-index: 1000; margin-right: 3%"
          ></v-select> -->
          <vs-select
            width="100%"
            v-model="visitType"
            placeholder="Visit Type"
            multiple
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in visitTypes"
            />
          </vs-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range',
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            style="width: 240px; margin-right: 3%"
            @update="analyzePagination"
          ></date-range-picker>
        </vs-col>
        <vs-col vs-justify="flex-start" vs-align="center" vs-w="1.75">
          <v-select
            id="courseselect"
            class="w-full"
            placeholder="Course"
            v-model="course"
            :options="courses"
            label="text"
            style="height: 20px; z-index: 1000; margin-right: 3%"
          ></v-select>
        </vs-col>
        <vs-col vs-justify="flex-start" vs-align="center" vs-w="1.75">
          <vs-input
            class="inputx"
            placeholder="Can-ID"
            v-model="identity_seaarch"
          />
        </vs-col>

        <vs-col
          style="margin-bottom: 1%"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="1.5"
        >
          <vs-button
            color="dark"
            style="margin-right: 2%"
            type="gradient"
            icon="search"
            @click="analyzePagination"
          ></vs-button>
          <vs-button
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refresh()"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row></vs-row>
      <vs-tabs v-model="tabsLink" :color="color">
        <vs-tab label="Pending" @click="configureTabDetails('warning', 'No')">
          <VisitApprovalTable :spoc_names="rawBde"></VisitApprovalTable>
        </vs-tab>
        <vs-tab
          label="Rejected"
          @click="configureTabDetails('danger', 'Rejected')"
        >
          <VisitApprovalTable :spoc_names="rawBde"></VisitApprovalTable>
        </vs-tab>
        <vs-tab label="Approved" @click="configureTabDetails('success', 'Yes')">
          <VisitApprovalTable :spoc_names="rawBde"></VisitApprovalTable>
        </vs-tab>
        <vs-tab label="Total" @click="configureTabDetails('success', 'Total')">
          <VisitApprovalTable :spoc_names="rawBde"></VisitApprovalTable>
        </vs-tab>
      </vs-tabs>
      <vs-row style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-chip color="primary">
            <b>{{ count }}</b>
          </vs-chip>
          <vs-pagination
            :total="totalpagination"
            v-model="selectedpagination"
          ></vs-pagination>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus.js";
import VisitApprovalTable from "../components/pagesComponents/VisitApprovalTable.vue";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    vSelect,
    DateRangePicker,
    VisitApprovalTable,
    Treeselect,
  },

  data() {
    return {
      ccf_spoc_ids: [],
      selected_ccf_spocs: [],
      city_classification_list: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      cities_list: [],
      gm_spocs_list: [],
      gm_reporting_spoc_ids: [],
      selected_gm_spoc: null,
      gm_spocs: [],
      selectedSpocRadio: "CM",
      dateRange: {
        startDate: "",
        endDate: new Date(),
      },
      cities: [],
      no_reporting_to:false,
      city: "",
      selectedpagination: 1,
      courses: [
        "CMA",
        "CFA",
        "FRM",
        "CPA",
        "CPA-AA",
        "USP",
        // "MAcc",
        // "IIMI-BA",
        // "IIMI-AA",
        // "IIML-FT",
        // "XLRI-HR",
        // "XLRI-SH",
      ],

      course: "",
      visitTypes: ["First", "Repeat", "Enrollment"],
      visitType: [],
      totalpagination: 0,
      count: 0,
      identity_seaarch: "",
      tabsLink: 0,
      color: "warning",
      visitStatus: "No",
      initSelectedBDE: [],
      rawcities: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      spoc_tree_data: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      rawBde: [],
      searchIn: {
        id: "",
        name: "",
      },
      logged_in_team: "",
      user_id: "",
      visitor_access_id:['2446','2447','2363','2364','2541','2484','2336','2563','2434'],
      admin_access_spocs : [
        "ghouse.ahmed@mileseducation.com",
        "athira.m@mileseducation.com",
        "gagan.jot@mileseducation.com",
        "krishna.kanth@mileseducation.com",
        "anjum.taj@mileseducation.com",
        "nidhin.ram@mileseducation.com",
        "reshma.johnson@mileseducation.com",
        "satram.chaitanya@mileseducation.com",
        "sharon.j@mileseducation.com",
        "simran.basha@mileseducation.com",
      ],
      spoc_email_id: '',
      login_spoc_id:"",
    };
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    this.logged_in_team = localStorage.getItem("team");
    this.spoc_email_id = localStorage.getItem("email_id");
    if (this.admin_access_spocs.includes(this.spoc_email_id)) {
        this.logged_in_team = 'Admin';
    } else if(localStorage.getItem("team")=='GM' && localStorage.getItem("sub_team") == 'GM'){
    this.logged_in_team = 'Admin'
    }
    this.user_id = localStorage.getItem("user_id");
    console.log("user_id",localStorage.getItem("user_id"))
    this.adminRedirect();
    this.cm2Redirect();
    this.$store.commit("CLEAR_MWB_LEADS");
    EventBus.$on("reload-visits", () => {
      this.analyzePagination();
    });
    let date = new Date();
    let pastdate = date.getDate() - 7;
    date.setDate(pastdate);
    this.dateRange.startDate = date;

    if (this.logged_in_team == 'GM' && (localStorage.getItem("sub_team") == 'SR' || localStorage.getItem("sub_team") == 'GM')) {
      this.getSpocsOfGM();
    }else{

    if (this.selectedSpocRadio === "CM") {
      this.getSpocs();
    } else if (this.selectedSpocRadio === "SR") {
      this.getSRSpocs();
    } else if (this.selectedSpocRadio === "GM") {
      this.getGmSpocs();
    }
    }
  },
  watch: {
    selected_ccf_spocs(val) {
      this.ccf_spoc_ids = [];
      val.forEach((element) => {
        this.ccf_spoc_ids.push(element.id);
      });
      if (val.length != 0) {
        this.initSelectedBDE = [];
      }
    },
    selected_gm_spoc(val) {
      this.getGmReportingSpocs(val);
    },
    initSelectedBDE(val) {
      console.log("selected spocc", val);
    },
    selectedpagination: function (value) {
      this.loadvisits(value);
    },
    city() {
      this.analyzePagination();
    },
    selectedSpocRadio(val) {
      if (val === "CM") {
        this.getSpocs();
      } else if (val === "SR") {
        this.getSRSpocs();
      } else if (val === "GM") {
        this.selected_gm_spoc = null;
        this.getGmSpocs();
      }
    },
  },
  methods: {
    getSpocsOfGM() {
            let gm_id = localStorage.getItem("spoc_id");
            let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                   console.log("getSpocsOfGM", response.data);
                   this.calling(response.data.spocs);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
    getCityClassificationSpocs(spocs_data) {
      this.cities_list = [...new Set(spocs_data.map((item) => item.city))];
      console.log("cities list order", this.cities_list);
      this.city_classification_list[0].children = [];
      for (let i = 0; i < this.cities_list.length; i++) {
        const element = this.cities_list[i];
        if (element != null) {
          var obj = {
            id: element,
            label: element,
            children: [],
          };
          this.city_classification_list[0].children.push(obj);
         
        }
      }

      this.city_classification_list[0].children =
        this.sortNestedArrayAlphabetically(
          this.city_classification_list[0].children
        );
      spocs_data.forEach((element) => {
        this.city_classification_list[0].children.forEach((city) => {
          if (element.city == city.label) {
            if (
              !city.children.find(
                (item) => item.label === element.city_classification
              )
            ) {
              //to remove city classification duplicates
              if (element.city_classification != null) {
                // var obj = {
                //   id: element.city + "_" + element.city_classification, // x is in more than one city
                //   label: element.city_classification,
                //   children: [],
                // };
                // city.children.push(obj);

                if(this.login_spoc_id == 132 || this.login_spoc_id == 937 || this.login_spoc_id == 39){
                    let obj = {
                      id: element.city + "_" + element.city_classification, // x is in more than one city
                      label: element.city_classification,
                      children: [],
                    };
                    city.children.push(obj);
                }
                else{
                  if(element.full_name.substring(0,1) != 'X'){
                    let obj = {
                      id: element.city + "_" + element.city_classification, // x is in more than one city
                      label: element.city_classification,
                      children: [],
                    };
                    city.children.push(obj);
                    }
                }
               
              }
            }
          }
        });
      });
      spocs_data.forEach((element) => {
        this.city_classification_list[0].children.forEach((city) => {
          city.children.forEach((ccf) => {
            if (
              element.city == city.label &&
              element.city_classification == ccf.label
            ) {
              var obj = {
                id: element.id,
                label: element.full_name,
              };
              ccf.children.push(obj);
            }
          });
        });
      });
      console.log("spocs_data", spocs_data);
    },
    configureTabDetails(color, status) {
      this.color = color;
      this.visitStatus = status;
      this.analyzePagination();
    },
    analyzePagination() {
      if (this.selectedpagination === 1) {
        this.loadvisits(1);
      } else {
        this.selectedpagination = 1;
      }
    },
    getGmSpocs() {
      let url = `${constants.SERVER_API}getGMsWithSpocIds`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getGMSpocs", response);
          this.gm_spocs = [];
          this.gm_spocs = response.data;
          for (let i = 0; i < this.gm_spocs.length; i++) {
            this.gm_spocs_list.push(this.gm_spocs[i].full_name);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getGmReportingSpocs(selected_gm_spoc) {
      this.gm_reporting_spoc_ids = [];
      for (let i = 0; i < this.gm_spocs.length; i++) {
        const element = this.gm_spocs[i];
        if (selected_gm_spoc == element.full_name) {
          this.gm_reporting_spoc_ids = element.reporting_spoc_ids;
        }
      }
    },
    getSpocs() {
      this.initSelectedBDE = [];
      this.treeDataBDE[0].children = [];
      this.spoc_tree_data[0].children = [];
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.loggedin = response.data.logged_in_user_id;
          this.spoc_names = response.data.spocs;
          // this.extractSpocByCity(response.data.spocs);
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          console.log("spocs data length", this.spoc_names.length);
          this.calling(this.spoc_names);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSRSpocs() {
      this.initSelectedBDE = [];
      this.treeDataBDE[0].children = [];
      this.spoc_tree_data[0].children = [];
      let url = `${constants.ADMIN_SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.loggedin = response.data.logged_in_user_id;
          this.spoc_names = response.data.spocs;
          // this.extractSpocByCity(response.data.spocs);
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.calling(response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    calling(spoc_names) {
      if(this.logged_in_team == 'GM' && (localStorage.getItem("sub_team") == 'SR' || localStorage.getItem("sub_team") == 'GM')){
        console.log("spocnames", spoc_names);
        this.rawBde = spoc_names;
        this.spoc_tree_data[0].children = [];
        spoc_names.forEach((server_spoc) => {
          let objj = {
            id: server_spoc.city,
            label: server_spoc.city,
            children: [],
          }
          this.spoc_tree_data[0].children.push(objj);
        })
        let jsonObject = this.spoc_tree_data[0].children.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

        spoc_names.forEach((server_spoc) => {
          console.log("uniqueArray", uniqueArray);
        uniqueArray.forEach((bde) => {
                if (bde.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bde.children.push(obj);
                }
            });

          })


        this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(uniqueArray);
        }else{
      this.rawBde = spoc_names;
      this.spoc_tree_data[0].children = [];
      var unique = spoc_names
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.rawcities.push(key);
          var obj = {
            id: key,
            label: key,
            children: [],
          };
          this.spoc_tree_data[0].children.push(obj);
        }
      }
      spoc_names.forEach((server_spoc) => {
        

        if (this.logged_in_team == "GM") {
          if (localStorage.getItem("spoc_id") == server_spoc.reporting_to) {
            this.spoc_tree_data.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                }
               
              });
            });
          } else if (
            localStorage.getItem("spoc_id") != server_spoc.reporting_to
          ) {
            if(this.visitor_access_id.includes(this.user_id) == true){
              this.no_reporting_to = true;
              this.spoc_tree_data.forEach((bde) => {
                bde.children.forEach((bdechid) => {
                  if (bdechid.label === server_spoc.city) {
                    var obj = {
                      id: server_spoc.full_name,
                      label: server_spoc.full_name,
                    };
                    bdechid.children.push(obj);
                  }
                
                });
              });
              this.initSelectedBDE = ["All"]
            }
          }
        }
        if (this.logged_in_team != "GM") {
          this.spoc_tree_data.forEach((bde) => {
            bde.children.forEach((bdechid) => {
              // if (bdechid.label === server_spoc.city) {
              //   var obj = {
              //     id: server_spoc.full_name,
              //     label: server_spoc.full_name,
              //   };
              //   bdechid.children.push(obj);
              // }
              if (bdechid.label === server_spoc.city) {
                  if(this.login_spoc_id == 132 || this.login_spoc_id == 937 ||  this.login_spoc_id == 39){
                    let obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                  }
                  else{
                    if(server_spoc.full_name.substring(0,1) != 'X'){
                      let obj = {
                        id: server_spoc.full_name,
                        label: server_spoc.full_name,
                      };
                      bdechid.children.push(obj);
                      }
                  }
                }
            });
          });
        }
      
      });
      console.log("this.spoc_tree_data", this.spoc_tree_data);
      if (this.logged_in_team == "GM") {
        for (let i = 0; i < this.spoc_tree_data[0].children.length; i++) {
          const element = this.spoc_tree_data[0].children[i];
          if (element.children.length != 0) {
            this.treeDataBDE[0].children.push(element);
          }
        }
        let gm_spocs = [];
        for (let i = 0; i < spoc_names.length; i++) {
          const element = spoc_names[i];
          if (localStorage.getItem("spoc_id") == element.reporting_to) {
            gm_spocs.push(element);
          }
          // else if(localStorage.getItem("spoc_id") == element.reporting_to){
          //   gm_spocs.push(element);
          // }
        }
        if (this.selectedSpocRadio == "CM") {
          this.getCityClassificationSpocs(gm_spocs);
        }
      } 

      if (this.logged_in_team != "GM") {
        this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
          this.spoc_tree_data[0].children
        );
        if (this.selectedSpocRadio == "CM") {
          this.getCityClassificationSpocs(spoc_names);
        }
      }
      if (this.selectedSpocRadio != "CM") {
        this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
          this.spoc_tree_data[0].children
        );
      }
      console.log("this.treeDataBDE", this.treeDataBDE[0].children.length);
      if (this.rawBde.indexOf(this.searchIn) !== -1) {
        this.initSelectedBDE.push(this.searchIn.name);
      } else {
        if (this.treeDataBDE[0].children.length != 0) {
          this.initSelectedBDE.push(this.treeDataBDE[0].children[0].label);
        }
      }
      // console.log(this.treeDataBDE);
      this.loadvisits(1);
    }
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.city = this.cities[0];
      this.loadvisits(1);
    },
    refresh() {
      this.identity_seaarch = "";
      this.city = this.cities[0];
      this.dateRange.endDate = new Date();
      let date = new Date();
      let pastdate = date.getDate() - 7;
      date.setDate(pastdate);
      this.dateRange.startDate = date;
      this.analyzePagination();
    },
    EmitLoadEvent() {
      EventBus.$emit("loadVisits");
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    loadvisits(pagination) {
      this.$vs.loading();
      let obj = {
        from_date: this.datatoTimestamp(this.dateRange.startDate),
        to_date: this.datatoTimestamp(this.dateRange.endDate),
        visit_approved: this.visitStatus,
        page: pagination,
        identity: this.identity_seaarch,
        spoc_ids: "",
        courses: this.course,
        visit_status: this.visitType.join(),
      };
      let bde = [];
      this.initSelectedBDE.forEach((sort) => {
        if (sort === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            child.children.forEach((acutal_spoc) => {
              bde.push(acutal_spoc.label);
            });
          });
        } else {
          let city = false;
          this.treeDataBDE[0].children.forEach((child) => {
            if (child.label === sort) {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
            bde.push(sort);
          }
        }
      });
      let bdeid = [];
      this.ccf_spoc_ids.forEach((element) => {
        bdeid.push(element);
      });
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      if (this.selectedSpocRadio != "GM") {
        obj.spoc_ids = bdeid.join();
      }
      if (this.selectedSpocRadio == "GM") {
        obj.spoc_ids = this.gm_reporting_spoc_ids.join();
      }
      console.log(obj);
      let url = `${constants.SERVER_API}getVisits`;
      // let obj = {
      //   from_date: this.datatoTimestamp(this.dateRange.startDate),
      //   to_date: this.datatoTimestamp(this.dateRange.endDate),
      //   visit_approved: this.visitStatus,
      //   city: this.city,
      //   page: pagination,
      //   identity: this.identity_seaarch
      // };
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status !== "success") {
            this.handleNotification(response);
            this.$vs.loading.close();
          } else {
            response.data.visit_data.data.forEach((visit) => {
              visit.visit_count = visit.engagement.visit_count;
              visit.visit_status = visit.engagement.visit_status;
              visit.coming_from = visit.engagement.coming_from;
              visit.visit_approved = visit.engagement.visit_approved;
              visit.engagement_added_id = visit.engagement.added_by_id;
              visit.tooltipengagement = visit.engagement.details;
              visit.engagement_id = visit.engagement.id;
              visit.engagements = [];
              visit.mobile = [];
              visit.emails = [];
            });
            this.totalpagination = response.data.visit_data.last_page;
            this.count = response.data.visit_data.total;
            this.$vs.loading.close();
            this.$store.commit(
              "MOUNT_MWB_LEADS",
              response.data.visit_data.data
            );
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style lang="scss">
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
@import "@/assets/scss/vuesax/pages/dropdown.scss";
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scrollbar {
  overflow-y: scroll;
}
</style>
